/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */

@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '~primeicons/primeicons.css';
//@import '~primeng/resources/themes/mdc-light-indigo/theme.css';
@import '~primeng/resources/themes/saga-blue/theme.css';
@import '~primeng/resources/primeng.min.css';
@import "~ngx-toastr/toastr.css";
@import "~angular-calendar/css/angular-calendar.css";
@import '~primeflex/primeflex.css';



@import "./app/commons/css/custom-table.css";
@import "./app/commons/css/custom-filter.css";
@import "./app/commons/css/dialog.css";
@import "./app/commons/css/buttons.css";
@import "./app/commons/css/grid.css";
@import "./app/commons/css/popup.css";

//datatimepicker css
@import '@mat-datetimepicker/core/datetimepicker/datetimepicker-theme.scss';

.mat-datetimepicker-calendar-header {
    color: green !important;
}

html {
    height: 100%
}


body {
    //height: 100vh;
    //@debug: linear-gradient(157deg, #008fe2 20%, #00b29c 100%);
    //background-color: #AAB3B3;
    background-color: #797d82;
    margin: 0;
    background-attachment: fixed;
}




@font-face {
    font-family: 'CarettaOffice';
    src: url("./assets/caretta/caretta.otf") format("opentype");
}

@font-face {
    font-family: 'MULTIDOM';
    src: url("./assets/caretta/multidom.otf") format("opentype");
}

.toast-title{
    font-family: CarettaOffice;
    FONT-SIZE: 25px;
}

.toast-container .ngx-toastr{
    width: 100%;
}

.pull-right {
    float: right;
}

h1,
h2,
h3,
h4,
h5 {
    color: #536c79;
}

.mat-primary.darkBlue {
    background: #415c77;
}

.mat-primary.lightRed {
    background: #dc3545;
}

.show {
    display: block;
}

.hide {
    display: none;
}

.floatRight {
    float: right;
}

.wrap-center {
    margin-left: auto;
    margin-right: auto;
    width: 300px;
}

.mat-dialog-content .mat-form-field {
    width: 100%;
}

.mat-input-element:disabled {
    color: black;
}

.mat-select-disabled .mat-select-value {
    color: black;
}

.mat-expansion-panel-header.mat-expanded {
    border: 1px dashed #415c77;
    border-bottom: none;
}

.mat-expansion-panel-content {
    border: 1px dashed #415c77;
    border-top: none;
}

.mat-expanded {
    background: #F2FBFF;
}

.mat-expansion-panel {
    margin-top: 10px !important;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.marginRight30px {
    margin-right: 30px !important;
}

.marginRight20px {
    margin-right: 20px !important;
}

.marginRight10px {
    margin-right: 10px;
}

.blackSVG {
    background: black;
}

.ui-panel .ui-panel-titlebar {
    background: #415c77 !important;
    color: white !important;
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
}

.ui-widget-content {
    border-radius: 15px !important;
}

.mat-chip-list-stacked .mat-chip-list-wrapper .mat-standard-chip {
    width: 90% !important;
}

.mat-progress-spinner circle,
.mat-spinner circle {
    stroke: #415c77;
}

.white-form {
    background: white;
    text-align: center;
    margin-top: 20px;
    padding: 15px;
}

.mat-input-element .mat-form-field-autofill-control::placeholder {
    color: white;
    opacity: 1;
    /* Firefox */
}

.production.mat-expansion-panel-header {
    background: #29363d !important;
    color: white !important;
}

.productionBlue .mat-expansion-panel-content.ng-trigger.ng-trigger-bodyExpansion.mat-expanded {
    background: #29363d !important;
}

.expandConstants .mat-expansion-panel-content {
    z-index: 99 !important;
    position: absolute !important;
    width: 20.2% !important;
}

@media only screen and (min-width: 1200px) {
    .container {
        width: 100% !important;
    }
}

@media only screen and (max-width: 600px) {
    .expandConstants .mat-expansion-panel-content {
        width: inherit !important;
    }

    .cdk-overlay-pane {
        max-width: 100vw !important;
    }
}

.toast-top-right {
    top: 50px;
    right: 10px;
}

@media only screen and (max-width: 400px) {
    .ui-widget-content {
        width: 100%;
    }
}

.white-placeholder::placeholder {
    color: white !important;
}

.white-placeholder {
    color: white !important;
}

.dark-placeholder::placeholder {
    color: #536c79 !important;
}

.dark-placeholder {
    color: #536c79 !important;
}

.dark-placeholder:focus {
    color: #536c79 !important;
}

.cdk-overlay-container {
    z-index: 1003 !important;
}

.ui-g-1,
.ui-g-2,
.ui-g-3,
.ui-g-4,
.ui-g-5,
.ui-g-6,
.ui-g-7,
.ui-g-8,
.ui-g-9,
.ui-g-10,
.ui-g-11,
.ui-g-12 {
    padding: 2px !important;
}

@media only screen and (min-width: 500px) {
    ::-webkit-scrollbar {
        width: 15px !important;
        height: 15px !important;
        background: rgb(160, 160, 160) !important;
        border-width: 1px !important;
        border-style: solid !important;
        border-color: rgb(167, 167, 167) !important;
        border-image: initial !important;
    }

    ::-webkit-scrollbar-button {
        display: none !important;
    }

    ::-webkit-scrollbar-track {
        background: rgb(160, 160, 160) !important;
    }

    ::-webkit-scrollbar-thumb {
        min-height: 28px !important;
        background: rgb(30, 30, 30) !important;
    }

    ::-webkit-scrollbar-track {
        box-shadow: none !important;
        border-width: 0px !important;
        border-style: initial !important;
        border-color: initial !important;
        border-image: initial !important;
    }

    ::-webkit-scrollbar-track {
        box-shadow: none !important;
        border-width: 0px !important;
        border-style: initial !important;
        border-color: initial !important;
        border-image: initial !important;
    }

    // ::-webkit-scrollbar {
    //     width: 6px;
    // }

    // ::-webkit-scrollbar-thumb {
    //     background-color: #888;
    // }

    // ::-webkit-scrollbar-track {
    //     background-color: #f0f0f0;
    // }
}

.alignRight {
    text-align: right !important;
}

.alignLeft {
    text-align: left !important;
}

.alignCenter {
    text-align: center !important;
}

.overlay-container {
    z-index: 1001 !important;
}

.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate,
.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate {
    background: #415c77 !important;
}

.ui-tabmenu.ui-widget.ui-widget,
.ui-tabmenu.ui-widget.ui-widget-content.ui-corner-all {
    width: 100%;
    margin: 0 auto !important;
    height: 47px !important;
}

.select-search-input {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    display: inline-block;
    height: 36px;
    padding: 0px 12px;
    width: 100%;
    margin-top: 5px;
    background: #fff;
    border: none;
    border-bottom: 1px solid #c0c0c0;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-border-radius: 1px;
    -webkit-border-radius: 1px;
    border-radius: 1px;
    font-size: 15px;
    color: #404040;
    outline: none;
}

datatable-header-cell.datatable-header-cell.sortable.resizeable.scannedItem {
    padding: 20px !important
}

/*svg size in app*/

.svg25Px {
    width: 25px !important;
    height: 25px !important;
    border-radius: 0 !important;
    vertical-align: middle !important;
}

.svg30Px {
    width: 30px !important;
    height: 30px !important;
    border-radius: 0 !important;
    vertical-align: middle !important;
}

.svg35Px {
    width: 35px !important;
    height: 35px !important;
    border-radius: 0 !important;
    vertical-align: middle !important;
}

.svg50Px {
    width: 50px !important;
    height: 50px !important;
    border-radius: 0 !important;
    vertical-align: middle !important;
}

.svgAssistPx {
    width: 45px !important;
    height: 45px !important;
    border-radius: 0 !important;
    vertical-align: middle !important;
}

.partnerCompanySvg {
    width: 30px !important;
    height: 35px !important;
    border-radius: 0 !important;
    vertical-align: middle !important;
    margin-right: 5px !important;
}

.cuiCompanySvg {
    width: 30px !important;
    height: 30px !important;
    border-radius: 0 !important;
    vertical-align: middle !important;
    margin-right: 5px !important;
}

.locationSvg {
    width: 28px !important;
    height: 30px !important;
    border-radius: 0 !important;
    vertical-align: middle !important;
    margin-right: 5px !important;
}

/* style for scanning input */

.inputWrapper {
    position: relative;
    cursor: pointer;
}

.scanInput {
    height: 30px;
    display: flex;
}

.mat-badge-content.mat-badge-active {
    background: #04c2c9 !important;
}

//sales assistant mobile change input look
#quantity .mat-form-field-wrapper {
    padding-bottom: 0 !important;
}

#quantity .mat-form-field-underline {
    bottom: 0;
}

.inputWrapper input {
    margin-left: 10px;
    color: #536c79;
    width: 90%;
    border: none;
    float: left;
    height: 100%;
    outline: none;
    letter-spacing: 1px;
    text-align: left;
    background: transparent;
    overflow: hidden;
}

.corners {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background: transparent;
}

.corner {
    position: absolute;
    width: 10px;
    height: 10px;
    border-style: solid;
}

.neutral {
    height: inherit;
}

.neutral .corner {
    color: red;
}

.neutral .corners.scan .corner {
    color: #14a756;
}

.corner.topLeft {
    top: 0;
    left: 0;
    border-width: 3px 0 0 3px;
}

.corner.topRight {
    top: 0;
    right: 0;
    border-width: 3px 3px 0 0;
}

.corner.bottomLeft {
    bottom: 0;
    left: 0;
    border-width: 0 0 3px 3px;
}

.corner.bottomRight {
    bottom: 0;
    right: 0;
    border-width: 0 3px 3px 0;
}

/*table cell padding */

.ngx-datatable.material .datatable-header .datatable-header-cell,
.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
    padding: .9rem 10px !important;
}

#box .mat-form-field-appearance-legacy .mat-form-field-label {
    color: #04C2C9 !important;
}

#box .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: rgba(255, 255, 255, 0.0) !important;
}

#box .mat-form-field.mat-focused .mat-form-field-label {
    color: #04C2C9;
}

#box .mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: rgba(255, 255, 255, 0.0);
}

#box input {
    border-bottom: 2px solid #04C2C9;
}

#box .mat-form-field-wrapper {
    padding-bottom: 0;
}

.mat-slider-horizontal {
    height: 30px !important;
}

.mat-slider-horizontal .mat-slider-ticks-container {
    // background-color: #415c77 !important;
    color: white !important;
}

span.mat-slider-thumb-label-text {
    background-color: #415c77 !important;
    color: white !important;
}

.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label,
.mat-accent .mat-slider-track-fill {
    background-color: #415c77 !important;
}

.mat-button .mat-button-focus-overlay,
.mat-icon-button .mat-button-focus-overlay {
    background: none !important;
}

.mat-menu-content:not(:empty) {
    padding: 20px 5px 8px 5px !important;
}



.ui-widget-overlay.ui-dialog-mask {
    z-index: 1000;
}

.p-dialog {
    background-color: white;
}

.p-dialog-header {
    background-color: white;
}

p-dialog .ui-dialog {
    overflow: auto;
    max-height: 80%;

}



.currentWeek .datatable-body-cell-label span {
    color: green !important;
}

.oldWeek .datatable-body-cell-label span {
    color: #dc3545 !important;
}

.confirmSvg {
    width: 40px;
    margin: auto;
    cursor: pointer;
}

.closeSvg {
    width: 30px;
    margin: auto;
    cursor: pointer;
}

.closeSvgRight {
    width: 30px;
    margin-left: 10px !important;
    margin-top: 5px !important;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 5px;
}

body .ui-dialog .ui-dialog-titlebar {
    background-color: white;
}


.flex {
    display: flex;
}

//angular material override dialog style
.mat-dialog-container {
    padding: 20px 20px 10px 20px !important;
}

.mat-dialog-content {
    margin: 0 !important;
    padding: 2px !important;
}

.mat-dialog-actions {
    margin-bottom: 0 !important;
    padding: 12px 0 0 0 !important;
}

//ngx datable overide cell padding
.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
    padding: 10px !important;
}

//primeng style dialog
p-header .dialog-title {
    font-size: 27px;
    color: #536c79;
    width: calc(100% - 40px);
}

.ui-widget-content {
    border-radius: 0 !important;
}

.dialog-product {
    background: #eee !important
}

.syncTable datatable-header.datatable-header.ng-star-inserted {
    min-width: 700px !important;
}

.order-left-panel .mat-tab-header {
    background: white;
    border: 1px solid lightgray;
    width: 310px;
    margin: 0 5px;
}

.order-left-panel .mat-tab-label {
    min-width: 150px;
}

.cal-disabledd {
    background-color: #eee;
    pointer-events: none;
}

//table
.ngx-datatable {
    cursor: default;
}

.ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group {
    background-color: #8BE6EA;
}

.ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group {
    background-color: #8BE6EA;
}

.ngx-datatable.material .selected2Row {
    background-color: #d0ffd8;
    color: #FFF;
}

.ngx-datatable.material:not(.cell-selection) .datatable-body-row.selected2Row:hover .datatable-row-group {
    background-color: #d0ffd8;
}

//class scadentar table when line selected
virtual-keyboard .mat-raised-button.mat-primary {
    background: #273238;
    border-radius: 0;
}

virtual-keyboard .mat-mini-fab.mat-primary {
    background: #273238;
}

.bottom-keyboard {
    max-width: 100vw !important;
}

//mat check box
.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
    background-color: #16a085;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: #16a085;
}

//calendar

.cal-month-view .cal-day-cell.cal-today .cal-day-number {
    margin-top: 4px;
    margin-bottom: 0;
}

.importCommand .ui-dialog .ui-dialog-content {
    padding: 0 !important;

}

.enode-toolbar {
    display: flex;
    font-size: 35px;
    margin: 0 5px;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #3ac47d;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: #3ac47d;
}


mat-form-field.noLabel .mat-form-field-wrapper {
    padding-bottom: 5px;
}

mat-form-field.noLabel .mat-form-field-underline {
    bottom: 5px;
}

mat-form-field.noLabelBorder .mat-form-field-infix {
    border-top: 0;
}

/*avoid mat select problem when used in a modal for caretta*/
.cdk-global-overlay-wrapper,
.cdk-overlay-container {
    z-index: 9999 !important;
}

/*remove arrows in input type number*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance: textfield;
    /* Firefox */
}

.mat-select-trigger {
    text-align: center;

    font-weight: bold;
}

.mat-select-value-text {
    color: #536c79;
}

mat-grid-tile {
    border-radius: 0 !important;
}


.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
    background-color: #415c77 !important;
}




.ngx-datatable {
    .sortable .sort-btn:before {
        font-family: data-table;
        content: url(./assets/images/sort.svg);
    }

    .sortable .sort-btn.datatable-icon-down:before {
        content: url(./assets/images/sortasc.svg);
    }

    .sortable .sort-btn.datatable-icon-up:before {
        content: url(./assets/images/sortdesc.svg);
    }
}

.enableSort.ngx-datatable .datatable-header .datatable-header-cell .sort-btn {
    width: 20px;
    position: absolute;
    top: 8px;
    right: 10px;
    line-height: 100%;
    vertical-align: middle;
    display: inline-block;
    cursor: pointer;
}

.ngx-datatable.material .datatable-footer .page-count {
    line-height: 30px;
    height: 30px;
    padding: 0 1.2rem;
}

.mat-card {
    border-radius: 0 !important;
}

.mat-raised-button {
    border-radius: 0 !important;
}

.p-float-label input:focus~label,
.p-float-label input.p-filled~label,
.p-float-label textarea:focus~label,
.p-float-label textarea.p-filled~label,
.p-float-label .p-inputwrapper-focus~label,
.p-float-label .p-inputwrapper-filled~label {
    top: -.5rem;
}

//modal header margin

.p-dialog .p-dialog-header {
    padding: 0.5rem 1.5rem !important;
}


 .pulseIcon {
    display: block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: orange;
    border: 2px solid white;
    cursor: pointer;
    box-shadow: 0 0 0 rgba(0, 204, 255, 0.4);
    animation: pulse 3s infinite;
}

 .pulseIcon:hover {
        animation: none;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 204, 255, 0.4);
    }

    70% {
        box-shadow: 0 0 0 50px rgba(0, 204, 255, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(0, 204, 255, 0);
    }
}



// // Using the $theme variable from the pre-built theme you can call the theming function
// @include mat-datetimepicker-theme($theme);