
  /* filter style*/

.filterInput {
    width: 100%;
    color: black;
  }
  
  .inputFilter {
    border: 1px solid #a0a0a0;
    display: none;
    animation-name: expand-header-input;
    animation-duration: 1s;
    animation-fill-mode: both;
  }
  
  .inputFilterRevert {
    border: 1px solid #a0a0a0;
    animation-name: collapse-header-input;
    animation-duration: 1s;
    animation-fill-mode: both;
  }

  .inputFilter.show {
    display: block;
  }

  .filterInput{
    width: 100%;
  }
  