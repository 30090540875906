
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.css';
@import '~@swimlane/ngx-datatable/assets/icons.css';

.datatable-header-inner {
    background-color: #415c77;
    color: white;
    width: 100% !important;
    padding-bottom: 10px;
  }
  
  .datatable-header-cell div {
    font-family: CarettaOffice;
    font-size: 18px;
    color: #fff;
    line-height: 1.4;
  }
  
  .ngx-datatable.material.striped .datatable-row-odd {
    background: #f8f6ff;
  }
  
  .datatable-body-cell-label span {
    font-family: CarettaOffice;
    font-size: 18px;
    color: #536c79;
  }
  
  .datatable-row-wrapper {
    border-bottom: 1px solid #f2f2f2;
    box-sizing: border-box;
  }
  
  .datatable-row-wrapper:hover {
    background: #ececff;
  }


.stripped {
    color: #f8f6ff;
  }


datatable-body-cell.datatable-body-cell.NumericValue.sort-active, 
datatable-header-cell.datatable-header-cell.sortable.resizeable.NumericValue.ng-star-inserted {
  max-width: 70px;
  width:50px;
  text-align: right;
  text-overflow: ellipsis;
  margin-left: -7px;
}

datatable-header-cell.datatable-header-cell.sortable.resizeable.NumericValue.ng-star-inserted input {
  width:50px !important;
}

.searchTable .mat-input-container {
    margin-top: -24px;
  }
  
  .searchTable .mat-input-underline {
    display: none;
  }

  .datatable-header-cell div {
    margin-top:-7px !important;    
  }

  .select-table-header .mat-select-placeholder {
    color: white;
  }
  

  /* "refuzata" : "#D50115",
  "acceptata": "#8FFB9F",
  "neprocesata": "#AEAEAE",
  "lansata": "#009ACC",
  "finalizata": "#0007CC",
  "livrata": "#8FFB9F" */

  .refuzata {
    background: #FC4E68;
    color:white !important;
  }

  .acceptata {
    background: #997EE1;
  }
  .neprocesata {
    background: #FEC24B ;
    color:white !important;
  }

  /* #FEC24B; */

  .lansata {
    background: #18C9F6;
  }

  .finalizata {
    background: #32ACE1;
    color:white !important;
  } 
  
  .livrata {
    background: #27D99C
  } 

  /* .prodOrdersTable .datatable-body-cell-label span {
    color:white;
  }

  .prodOrdersTable .datatable-body-row:hover .datatable-row-group {
    background: orange !important;
  } */

  .scan-input {
    width: 70%;
    margin-right: 5px;
    height: 22px;
}

.scanIcon {
    cursor: pointer;
    width: 30px;
    height: 33px;
}