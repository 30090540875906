.closeDialog {
    overflow: hidden;
    width: 100%;
  }
  
  .closeDialog mat-icon {
    float: right;
    color: red;
    cursor: pointer
  }
  
  .mat-dialog-content {
    overflow: visible;
  }
  

  .mat-dialog-container {
    height: initial;
    overflow: visible;
  }