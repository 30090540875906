
.header-buttons mat-icon {
    color: white;
  }
  
  .header-buttons .header-button:not(:first-child) {
    margin-left: 10px;
    margin-top: 3px;
  }
  
  .header-buttons img {
    vertical-align: unset;
  }
  
  .header-buttons {
    background: #415c77;
    padding: 5px 5px;
    width: -moz-max-content;
    width: max-content;
    margin: auto;
    cursor: pointer;
  }

  .actionButtons {
    margin-top: 10px !important;
    width:25px !important;
    height: 25px !important;
  }

  .back {
    position: relative;
    display: inline-block;
    text-decoration: none;
    margin-left:10px;
    padding: 10px 10px 10px 40px;
  }
  
  .back h4 {
    color: #4A4F6A;
    font-size: 16px;
    margin:0;
    transform: translateY(8px);
    transition: transform 500ms 0s cubic-bezier(0.2, 0, 0, 1);
  }
  
  .back span {
    opacity: 0;
    color: #858BA9;
    font-size: 12px;
    font-weight: 300;
    display: inline-block;
    transform: translateY(10px);
    transition:
      transform 500ms 0s cubic-bezier(0.2, 0, 0, 1),
      opacity 500ms 0s cubic-bezier(0.2, 0, 0, 1)
  }
  
  .back div {
    top: 11px;
    left: 0;
    content: '';
    width: 30px;
    height: 30px;
    display: block;
    overflow: hidden;
    position: absolute;
    border-radius: 50%;
    transform: scale(1);
    background-color: #E9E7F2;
    transition: transform 400ms 0s cubic-bezier(0.2, 0, 0, 1.6);
  }
  
  .back div::after {
    top: 0;
    left: 0;
    content: '';
    width: 60px;
    height: 30px;
    position: absolute;
    background-position: 0 0;
    background-image: url('../../../assets/images/back.svg');
    transition: transform 400ms 0s cubic-bezier(0.2, 0, 0, 1);
  }
  
  .back:hover h4 {
    color: #171922;
  }
  
  .back:hover h4,
  .back:hover span {
    opacity: 1;
    transform: translateY(0);
  }
  
  .back:hover div {
    transform: scale(1.1);
    background-color: white;
    box-shadow:
      0 2px 10px 0 rgba(185,182,198,0.00),
      0 1px 3px 0 rgba(175,172,189,0.25);
  }
  
  .back:hover div::after {
    transform: translateX(-30px);
  }