.grid-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
  }
  
  .grid-item {
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8);
    padding: 20px;
    font-size: 30px; 
    text-align: center;
  }